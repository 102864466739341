<template>
  <div class="auth-wrapper auth-v1 px-2">
    <reset-password-modal
      title="Contraseña Establecida"
      message="Ahora puede ingresar usando su nueva contraseña. Haga click en 'Aceptar' para iniciar sesión."
      :show="showModal"
      @ok="onOk"
    />
    <overlay-default
      id="olyResetPassword"
      :show="loading"
    >
      <div>
        <b-card class="mb-0">
          <b-card-title
            title-tag="h3"
            class="font-weight-bold mb-1 text-center"
          >
            <logo-component class="mb-1" />
            Blanqueo de Contraseña
          </b-card-title>
          <validation-observer v-slot="{ valid }">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- Contraseña -->
              <b-form-group label="Contraseña">
                <validation-provider
                  v-slot="{ errors, failedRules }"
                  name="password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="inputResetPassword"
                      v-model="password"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                      :raw="false"
                      :type="passwordFieldType"
                      placeholder="Contraseña"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Campo obligatorio.
                  </small>
                  <small
                    v-if="failedRules.min"
                    class="text-danger"
                  >La contraseña debe tener un mínimo de 8 caracteres.
                  </small>
                </validation-provider>
              </b-form-group>
              <!-- Repetir Contraseña -->
              <b-form-group label="Repetir Contraseña">
                <validation-provider
                  v-slot="{ errors, failedRules }"
                  name="repeatPassword"
                  rules="required|min:8|equals:@password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="inputResetPasswordRepeat"
                      v-model="repeatPassword"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                      :raw="false"
                      :type="repeatPasswordFieldType"
                      placeholder="Repetir Contraseña"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="repeatPasswordToggleIcon"
                        @click="toggleRepeatPasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Campo obligatorio.
                  </small>
                  <small
                    v-if="failedRules.min"
                    class="text-danger"
                  >La contraseña debe tener un mínimo de 8 caracteres.
                  </small>
                  <small
                    v-if="failedRules.equals"
                    class="text-danger"
                  >Las contraseñas no coinciden.
                  </small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="!valid"
                @click="enviar"
              >
                Enviar
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </overlay-default>
  </div>
</template>
<script>
/* eslint-disable global-require */

import {
  extend,
} from 'vee-validate'
import {
  resetPasswordResource,
} from '@/services/resetPasswordResource'
import LogoComponent from '@/views/authentication/LogoComponent.vue'
import {
  logout,
} from '@/utils/login/loginUtils'
import ResetPasswordModal from './ResetPasswordModal.vue'

const logo = require('@/assets/images/logo/isologotipoatacc.png')

extend('equals', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
})

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordModal,
    LogoComponent,
  },
  data() {
    return {
      status: '',
      loading: false,
      password: null,
      repeatPassword: null,
      sideImg: logo,
      showModal: false,
      passwordFieldType: 'password',
      repeatPasswordFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    repeatPasswordToggleIcon() {
      return this.repeatPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.validateToken()
    this.setTokenInLocalStorage()
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    toggleRepeatPasswordVisibility() {
      this.repeatPasswordFieldType = this.repeatPasswordFieldType === 'password' ? 'text' : 'password'
    },
    setTokenInLocalStorage() {
      const { token } = this.$route.query
      window.localStorage.setItem('ls.X-AUTHENTICATION-TOKEN', `"${token}"`)
    },
    validateToken() {
      this.loading = true
      const { token } = this.$route.query
      resetPasswordResource().validateToken(token)
        .catch(response => {
          if (!response) {
            this.$toast('No se pudo contactar con el servidor, intente nuevamente en unos minutos.', { type: 'error' })
          } else if (response.data) {
            switch (response.data.code) {
              case 'JWT_INVALIDO':
              case 'JWT_VENCIDO':
                window.localStorage.removeItem('ls.X-AUTHENTICATION-TOKEN')
                this.$toast('Su link ya no es válido. Por favor, solicite un nuevo blanqueo de contraseña', { type: 'error' })
                break
              default:
                this.$toast('Se produjo un error al procesar su solicitud.', { type: 'error' })
                break
            }
          }
          // this.$router.push({ name: 'login' })
          logout(this)
        })
        .finally(() => {
          this.loading = false
        })
    },
    enviar() {
      const payload = {
        password: this.password,
      }
      this.loading = true
      resetPasswordResource().resetPassword(payload)
        .then(() => {
          this.showModal = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    onOk() {
      this.$router.push({ name: 'login' })
      this.showModal = false
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
