<template>
  <b-modal
    id="modalResetPassword"
    :visible="show"
    ok-title="Aceptar"
    :ok-only="true"
    centered
    button-size="sm"
    :title="title"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @ok="$emit('ok', $event)"
  >
    <b-card-text>{{ message }}</b-card-text>
  </b-modal>
</template>

<script>
export default {
  name: 'ResetPasswordModal',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
