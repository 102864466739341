var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('reset-password-modal',{attrs:{"title":"Contraseña Establecida","message":"Ahora puede ingresar usando su nueva contraseña. Haga click en 'Aceptar' para iniciar sesión.","show":_vm.showModal},on:{"ok":_vm.onOk}}),_c('overlay-default',{attrs:{"id":"olyResetPassword","show":_vm.loading}},[_c('div',[_c('b-card',{staticClass:"mb-0"},[_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-center",attrs:{"title-tag":"h3"}},[_c('logo-component',{staticClass:"mb-1"}),_vm._v(" Blanqueo de Contraseña ")],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Contraseña"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"inputResetPassword","state":errors.length > 0 ? false : null,"raw":false,"type":_vm.passwordFieldType,"placeholder":"Contraseña"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Campo obligatorio. ")]):_vm._e(),(failedRules.min)?_c('small',{staticClass:"text-danger"},[_vm._v("La contraseña debe tener un mínimo de 8 caracteres. ")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Repetir Contraseña"}},[_c('validation-provider',{attrs:{"name":"repeatPassword","rules":"required|min:8|equals:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"inputResetPasswordRepeat","state":errors.length > 0 ? false : null,"raw":false,"type":_vm.repeatPasswordFieldType,"placeholder":"Repetir Contraseña"},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.repeatPasswordToggleIcon},on:{"click":_vm.toggleRepeatPasswordVisibility}})],1)],1),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Campo obligatorio. ")]):_vm._e(),(failedRules.min)?_c('small',{staticClass:"text-danger"},[_vm._v("La contraseña debe tener un mínimo de 8 caracteres. ")]):_vm._e(),(failedRules.equals)?_c('small',{staticClass:"text-danger"},[_vm._v("Las contraseñas no coinciden. ")]):_vm._e()]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":!valid},on:{"click":_vm.enviar}},[_vm._v(" Enviar ")])],1)]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }